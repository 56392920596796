import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import styles from './index.module.scss';
import { SvgIcon, TooltipDisclaimer } from '@ui-elements';
import { toCamelCase } from '~/common/utils';
import { Loading, Button, IconEnum } from '~/common/components/ui-elements';
import { CallToAction } from '~/common/models';
import stylesHeroCard from '../hero-card/index.module.scss';
import cn from 'classnames';

type VariantAccessoryPacks = {
  header: string;
  description: string;
  fontColor: string;
  disclaimer: string;
  mainImage: string;
  cta: CallToAction[];
  pimIdentifier: string;
};

export const VariantAccessoryPacks = (props: VariantAccessoryPacks) => {
  const [accessoryIndex, setAccessoryIndex] = useState(0);
  const [dataList, setDataList] = useState<any[]>([]);
  const [translationWidth, setTranslationWidth] = useState(510);
  const [isLoading, setIsLoading] = useState(true);
  const containerRef = useRef<HTMLDivElement | null>(null);

  useLayoutEffect(() => {
    if (containerRef.current !== null) {
      if (containerRef.current?.clientWidth < 361) {
        setTranslationWidth(266);
      } else if (containerRef.current?.clientWidth < 376) {
        setTranslationWidth(280);
      } else if (containerRef.current?.clientWidth < 413) {
        setTranslationWidth(296);
      } else if (containerRef.current?.clientWidth < 745) {
        setTranslationWidth(276);
      } else if (containerRef.current?.clientWidth < 821) {
        setTranslationWidth(290);
      } else if (containerRef.current?.clientWidth < 1025) {
        setTranslationWidth(400);
      }
    }
  }, []);

  useEffect(() => {
    fetch(`/api/accessories/accessory-packs/variant/${props.pimIdentifier}`)
      .then((res) => res.json())
      .then((res) => {
        setDataList(res);
        setIsLoading(false);
      });
  }, []);

  return (
    <div
      id="variant-accessory-packs"
      className={styles.AccessoryPackWrapper}
      ref={containerRef}
    >
      <div className={styles.AccessoryPack}>
        <div className={styles.HeaderContainer}>
          <div dangerouslySetInnerHTML={{ __html: props.header }} />
          <div dangerouslySetInnerHTML={{ __html: props.description }} />
        </div>

        {isLoading && <Loading height="100%" />}
        {!isLoading && (
          <div className={styles.HeroContentContainer}>
            <div className={styles.ContentSlider}>
              <div
                style={{
                  ...(dataList?.length > 1 ? {} : { display: 'none' }),
                }}
              >
                <div className={styles.galleryArrow}>
                  <SvgIcon
                    type="chevronLeft"
                    size={2}
                    color={
                      accessoryIndex === 0
                        ? 'rgba(205,205,206,255)'
                        : 'rgba(85,86,87,255)'
                    }
                    onClick={() => {
                      if (accessoryIndex > 0) {
                        setAccessoryIndex((prev) => prev - 1);
                      }
                    }}
                  />
                </div>
              </div>
              <div className={styles.SliderContentContainer}>
                <div
                  className={styles.Slider}
                  style={{
                    ...{
                      transform: `translateX(-${
                        accessoryIndex * translationWidth
                      }px)`,
                    },
                    ...(dataList?.length > 1
                      ? {}
                      : { justifyContent: 'center' }),
                  }}
                >
                  {!!dataList &&
                    dataList.map((item, index) => {
                      return (
                        <div
                          className={styles.SliderContent}
                          key={index}
                          style={{
                            ...(dataList?.length > 1
                              ? {}
                              : { border: 'unset' }),
                            ...(dataList?.length > 1 && !item.longDescription
                              ? {}
                              : { width: 'fit-content' }),
                          }}
                        >
                          <h3>{item.description}</h3>
                          <p>{item.longDescription || ''}</p>
                          <ul>
                            {!!item.accessories &&
                              item.accessories.map((bullet, bulletIndex) => {
                                const model =
                                  window.location.pathname.split('/')[1] ?? '';
                                const accessoryUrl = `/${model}/accessories/${bullet.accessoryCategoryName
                                  .replace(/\s+/g, '-')
                                  .toLowerCase()}/${bullet.description
                                  .replace(/\s+/g, '-')
                                  .toLowerCase()}`;
                                return (
                                  <li key={bulletIndex}>
                                    <a href={accessoryUrl}>
                                      {bullet.description}
                                    </a>
                                  </li>
                                );
                              })}
                          </ul>
                          {props.cta?.map((cta, idx) => {
                            const model =
                              window.location.pathname.split('/')[1] ?? '';
                            const moreInfoUrl = `/${model}/accessory-packs/${item.itemCode.toLowerCase()}/${item.description
                              .replace(/\s+/g, '-')
                              .toLowerCase()}`;
                            const buttonProps: any = {
                              type: toCamelCase(cta.ctaButtonType),
                              href: moreInfoUrl,
                              target: cta.ctaUrl?.target ?? '_self',
                              onClick: () => {},
                            };
                            return (
                              <Button
                                key={idx}
                                className={cn(
                                  stylesHeroCard.HeroCardCTA,
                                  stylesHeroCard[
                                    `HeroCardCTA${buttonProps.type}`
                                  ]
                                )}
                                {...buttonProps}
                              >
                                {cta.ctaIcon && cta.ctaIcon !== 'none' && (
                                  <SvgIcon
                                    className={styles.HeroCardCTAIcon}
                                    type={cta.ctaIcon as keyof typeof IconEnum}
                                    color="#1637a0"
                                    size={1.2}
                                    strokeWidth={2}
                                  />
                                )}
                                <span
                                  style={
                                    buttonProps.type === 'text'
                                      ? { color: '#1637a0' }
                                      : {}
                                  }
                                >
                                  {cta.ctaUrl?.name || ''}
                                </span>
                              </Button>
                            );
                          })}
                        </div>
                      );
                    })}
                </div>
                <div
                  className={styles.SliderNavigationContainer}
                  style={{
                    ...(dataList?.length > 1 ? {} : { display: 'none' }),
                  }}
                >
                  {!!dataList &&
                    dataList.map((item, index) => {
                      const test = item;
                      return (
                        <div
                          className={styles.SliderNavigation}
                          key={index}
                          style={{
                            backgroundColor:
                              accessoryIndex === index
                                ? 'rgba(22,55,160,255)'
                                : 'rgba(216,216,216,255)',
                          }}
                          onClick={() => {
                            setAccessoryIndex(index);
                          }}
                        />
                      );
                    })}
                </div>
              </div>
              <div
                style={{
                  ...(dataList?.length > 1 ? {} : { display: 'none' }),
                }}
              >
                <div className={styles.galleryArrow}>
                  <SvgIcon
                    type="chevronRight"
                    size={2}
                    color={
                      accessoryIndex === dataList.length - 1
                        ? 'rgba(205,205,206,255)'
                        : 'rgba(85,86,87,255)'
                    }
                    onClick={() => {
                      if (accessoryIndex < dataList.length - 1) {
                        setAccessoryIndex((prev) => prev + 1);
                      }
                    }}
                  />
                </div>
              </div>
            </div>
            <div className={styles.ContentHero}>
              <div style={{ position: 'relative' }}>
                <img src={props.mainImage} alt="" />
                <div
                  className={styles.ImageDisclaimer}
                  style={{ color: `#${props.fontColor}` }}
                  dangerouslySetInnerHTML={{ __html: props.disclaimer }}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
